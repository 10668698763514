// eslint-disable-next-line no-unused-vars
const DataGatherHeaders = (_this) => {
  return {
    countCells: 12,
    massive: [
      {
        dataField: 'nps',
        cols: 2,
        rows: 1,
        text: 'НПС',
        // order: 0,
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'ochnMest',
        cols: 2,
        rows: 1,
        text: 'Кол-во очных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'ochnZaochnMest',
        cols: 2,
        rows: 1,
        text: 'Кол-во очно-заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'zaochnMest',
        cols: 2,
        rows: 1,
        text: 'Кол-во заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'region',
        cols: 2,
        rows: 1,
        text: 'Регионы, в которых планируется трудоустроить целевиков',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'add',
        cols: 2,
        rows: 1,
        text: '',
        variable: true,
        addButton: true,
      },
    ],
    regionMassive: [
      {
        dataField: 'nps',
        cols: 3,
        rows: 1,
        text: 'НПС',
        // order: 0,
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
        // template: (row) => {
        //   return row['sprOrgName'];
        // }
      },
      {
        dataField: 'ochnMest',
        cols: 3,
        rows: 1,
        text: 'Кол-во очных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'ochnZaochnMest',
        cols: 2,
        rows: 1,
        text: 'Кол-во очно-заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
      },
      {
        dataField: 'zaochnMest',
        cols: 2,
        rows: 1,
        text: 'Кол-во заочных мест',
        sortable: true,
        typeCell: 'string',
        variable: true,
        class: 'text-center',
        headerClass: 'text-center',
      },
      {
        dataField: 'add',
        cols: 2,
        rows: 1,
        text: '',
        variable: true,
        addButton: true,
      },
    ]
  };
}
export default DataGatherHeaders