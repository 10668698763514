<template>
  <div>
    <DataGatherRegion v-if="getUserRoles.find(el => el === 'REGION_ROLE')"></DataGatherRegion>
    <DataGatherFoiv v-if="getUserRoles.find(el => el === 'FOUNDER_ROLE')"></DataGatherFoiv>
<!--    <div v-else>-->
<!--      <p>Ошибка модуля заявки</p>-->
<!--    </div>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import DataGatherRegion from "@/views/private/regionData/DataGatherRegion";
import DataGatherFoiv from "@/views/private/foivData/DataGatherFoiv";

export default {
  name: "DataGatherView",
  components: {DataGatherFoiv, DataGatherRegion},
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
}
</script>

<style scoped>

</style>