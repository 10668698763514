<template>
  <div>
    <div class="d-flex direction-column" style="justify-content: end">
      <v-btn
        @click="blockData(userId)"
        class="text-none pa-3"
        :style="
          blocked
            ? 'background-color: #618AA7 !important;'
            : 'background-color: #00599B !important;'
        ">
        <span style="color: #fff">
          {{ blocked ? 'Ввод заблокирован' : 'Заблокировать ввод' }}
        </span>
      </v-btn>
      <!--      <v-btn-->
      <!--        v-if="blocked"-->
      <!--        @click="unblockData(userId)"-->
      <!--        class="text-none pa-3"-->
      <!--        style="'background-color: #00599B !important'"-->
      <!--      >-->
      <!--        <span style="color:#fff;">-->
      <!--          Разблокировать-->
      <!--        </span>-->
      <!--      </v-btn>-->
    </div>
    <header class="section-header section-preview__header">
      <h2 class="section-header__title">
        Сбор информации о количестве мест, необходимых для приема на целевое обучение
      </h2>
    </header>
    <wrapper-preview-category customClass="section-preview_lk">
      <GridTable
        :data-rows="dataGather"
        :blocked="blocked"
        :headers="tableHeaders.regionMassive"
        :headers-for-rows="tableHeaders.regionMassive"
        :num-cols="tableHeaders.countCells"
        :loading-data="loading"
        max-table-height="700px"
        @add-dialog="addRowDialog">
        <template #nps="{ row }">
          <div>{{ `(${row.sprSpec.specCode}) ${row.sprSpec.specName}` }}</div>
        </template>
        <template #ochnMest="{ row }">
          <div>{{ row.placesOchno }}</div>
        </template>
        <template #ochnZaochnMest="{ row }">
          <div>{{ row.placesOcnhoZaochno }}</div>
        </template>
        <template #zaochnMest="{ row }">
          <div>{{ row.placesZaochno }}</div>
        </template>
        <template #add="{ row }">
          <v-btn :disabled="blocked" icon @click="editDialogOpen(row)">
            <v-icon color="primary" size="24">mdi-pencil</v-icon>
          </v-btn>
          <v-btn :disabled="blocked" icon @click="deleteConfirmFunc(row.collectionId)">
            <v-icon color="primary" size="24">mdi-delete</v-icon>
          </v-btn>
        </template>
      </GridTable>
      <v-dialog v-model="deleteConfirmDialog" width="375">
        <v-card>
          <v-card-title>Подтверждение удаления строки</v-card-title>
          <v-card-actions style="justify-content: center">
            <v-btn class="dialog-btn" @click="deleteTableRow">Да</v-btn>
            <v-btn class="dialog-btn" @click="deleteConfirmDialog = false">Нет</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog" width="1300">
        <v-card>
          <v-card-title v-if="!editDialog"> Добавление информации </v-card-title>
          <v-card-title v-if="editDialog"> Изменение информации </v-card-title>
          <v-autocomplete
            v-model="tableNewRow.spec"
            :items="specSelectorsArray"
            @change="aspirantDisable($event)"
            label="НПС"></v-autocomplete>
          <v-text-field
            v-model="tableNewRow.ochnMest"
            label="Количество очных мест"
            type="number"></v-text-field>
          <v-text-field
            v-model="tableNewRow.ochnZaochnMest"
            label="Количество очно-заочных мест"
            :disabled="tableNewRow.uslId === 6"
            type="number"></v-text-field>
          <v-text-field
            v-model="tableNewRow.zaochnMest"
            label="Количество заочных мест"
            :disabled="tableNewRow.uslId === 6"
            type="number"></v-text-field>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="!editDialog" class="dialog-btn" @click="addTableRow">Добавить</v-btn>
            <v-btn v-if="editDialog" class="dialog-btn" @click="editTableRow">Сохранить</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </wrapper-preview-category>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import request from '@/services/request';
  import WrapperPreviewCategory from '@/components/GridTable/dataFolder/WrapperPreviewCategory';
  import GridTable from '@/components/GridTable/GridTable';
  import DataGatherHeaders from '@/components/GridTable/dataFolder/DataGatherHeaders';

  export default {
    name: 'DataGatherRegion',
    components: {
      GridTable,
      WrapperPreviewCategory,
    },
    data: () => ({
      tableHeaders: [],
      dataGather: [],
      specSelectorsArray: [],
      specSelectorsArrayConst: [],
      dialog: false,
      editDialog: false,
      userId: null,
      blocked: false,
      loading: false,
      deleteRowID: null,
      deleteConfirmDialog: false,
      tableNewRow: {
        id: null,
        spec: null,
        ochnMest: null,
        ochnZaochnMest: null,
        zaochnMest: null,
        uslId: null,
      },
    }),
    computed: {
      ...mapGetters('keycloak', {
        getAuthData: 'getAuthData',
        getUser: 'getUser',
        getUserRoles: 'getUserRoles',
        isRegion: 'isRegion',
      }),
    },
    created() {
      this.tableHeaders = DataGatherHeaders(this);
      this.getAllByRegion();
      this.getSelectorSpec();
    },
    methods: {
      ...mapActions('notifications', { addNotification: 'addNotification' }),

      async getAllByRegion() {
        this.loading = true;
        try {
          const res = await request({
            endpoint: `/api/collection/main/getAll?regionId=${this.getUser.regionID}`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            console.log('getAllByRegion успех');
            this.dataGather = res.data;
            await this.getUserId();
            this.loading = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
      aspirantDisable(arg) {
        const x = this.specSelectorsArray.find((e) => e.value === arg).uslId;
        this.tableNewRow.uslId = x;
      },
      addRowDialog() {
        this.editDialog = false;
        this.dialog = true;
        this.tableNewRow.id = 0;
        this.tableNewRow.spec = null;
        this.tableNewRow.ochnMest = null;
        this.tableNewRow.ochnZaochnMest = null;
        this.tableNewRow.zaochnMest = null;
        this.tableNewRow.uslId = 0;
      },
      editDialogOpen(row) {
        this.editDialog = true;
        this.dialog = true;
        this.tableNewRow.id = row.collectionId;
        this.tableNewRow.spec = row.sprSpec.specId;
        this.tableNewRow.ochnMest = row.placesOchno;
        this.tableNewRow.ochnZaochnMest = row.placesOcnhoZaochno;
        this.tableNewRow.zaochnMest = row.placesZaochno;
        this.tableNewRow.uslId = row.sprSpec.sprUsl.uslId;
      },
      async addTableRow() {
        if (this.tableNewRow.uslId === 6) {
          this.tableNewRow.ochnZaochnMest = null;
          this.tableNewRow.zaochnMest = null;
        }

        try {
          const res = await request({
            endpoint: `/api/collection/main/add`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              regionId: this.getUser.regionID,
              specId: this.tableNewRow.spec,
              placesOchno: this.tableNewRow.ochnMest,
              placesZaochno: this.tableNewRow.zaochnMest,
              placesOcnhoZaochno: this.tableNewRow.ochnZaochnMest,
            },
          });
          if (res.status === 200 && res.data) {
            await this.getAllByRegion();
            console.log('addTableRow успех');
            this.dialog = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
      async editTableRow() {
        try {
          const res = await request({
            endpoint: `/api/collection/main/update`,
            method: 'post',
            token: `${this.getAuthData.token}`,
            body: {
              collectionId: this.tableNewRow.id,
              regionId: this.getUser.regionID,
              specId: this.tableNewRow.spec,
              placesOchno: this.tableNewRow.ochnMest,
              placesZaochno: this.tableNewRow.zaochnMest,
              placesOcnhoZaochno: this.tableNewRow.ochnZaochnMest,
            },
          });
          if (res.status === 200 && res.data) {
            await this.getAllByRegion();
            console.log('editTableRow успех');
            this.dialog = false;
          }
        } catch (e) {
          console.log(e);
        }
      },
      async getSelectorSpec() {
        try {
          const res = await request({
            endpoint: `/api/spr/specCO/getAll`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            for (let i = 0; i < res.data.length; i++) {
              this.specSelectorsArrayConst.push({
                text: `(${res.data[i].specCode}) ${res.data[i].specName}`,
                value: res.data[i].specId,
                uslId: res.data[i].sprUsl.uslId,
              });
            }
            this.specSelectorsArray = this.specSelectorsArrayConst;
            for (let i = 0; i < this.dataGather.length; i++) {
              this.specSelectorsArray = this.specSelectorsArray.filter(
                (el) => el.value !== this.dataGather[i].sprSpec.specId,
              );
            }
            console.log('getSelectorSpec успех');
          }
        } catch (e) {
          console.log(e);
        }
        this.loading = false;
      },
      deleteConfirmFunc(rowID) {
        this.deleteConfirmDialog = true;
        this.deleteRowID = rowID;
      },
      async deleteTableRow() {
        try {
          const res = await request({
            endpoint: `/api/collection/main/delete?collectionId=${this.deleteRowID}`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            await this.getAllByRegion();
            this.deleteRowID = null;
            this.deleteConfirmDialog = false;
            console.log('deleteTableRow успех');
          }
        } catch (e) {
          console.log(e);
        }
      },
      async getUserId() {
        try {
          const res = await request({
            endpoint: `/api/get/user/username?username=${this.getUser.preferred_username}`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            this.userId = res.data.userId;
            if (res.data.userStatus === 'BLOCKED_FOR_PRINT') {
              this.blocked = true;
            }
            console.log('getUserId успех');
            // await this.blockData(res.data.userId);
          }
        } catch (e) {
          console.log(e);
        }
      },
      async blockData(arg) {
        if (!this.blocked) {
          try {
            const res = await request({
              endpoint: `/api/block/for/print?userId=${arg}`,
              method: 'get',
              token: `${this.getAuthData.token}`,
            });
            if (res.status === 200 && res.data) {
              await this.getUserId();
              console.log('blockData успех');
            }
          } catch (e) {
            console.log(e);
          }
        }
      },
      async unblockData(arg) {
        try {
          const res = await request({
            endpoint: `/api/admin/unblock?userId=${arg}`,
            method: 'get',
            token: `${this.getAuthData.token}`,
          });
          if (res.status === 200 && res.data) {
            await this.getAllByRegion();
            console.log('unblockData успех');
          }
        } catch (e) {
          console.log(e);
        }
      },
    },
  };
</script>

<style lang="sass">
  .yellow-background
    background-color: #fff3cd
    color: #D4990A

  .section-preview__header
    font-size: 24px
    font-weight: bold
    margin: 16px 0
    color: #334D6E

  .dialog-btn
    .v-btn__content
      padding: 10px 5px

  .v-autocomplete
    padding: 0 24px

  .v-text-field
    padding: 0 24px

  .mdi-btns
    align-self: start
</style>
